import React from 'react';

function Portfolio(props) {
  const { data } = props;

  const renderProjects = () => {
    return data.projects.map((projects) => {
      return <div key={projects.title} className="columns portfolio-item">
        <div className="item-wrap">
          <a href={projects.url} title={projects.title}>
            <img alt={projects.title} src={'images/portfolio/' + projects.image} />
            <div className="overlay">
              <div className="portfolio-item-meta">
                <h5>{projects.title}</h5>
                <p>{projects.category}</p>
              </div>
            </div>
            <div className="link-icon"><i className="fa fa-link"></i></div>
          </a>
        </div>
      </div>
    });
  }

  return (
    <section id="portfolio">

      <div className="row">

        <div className="twelve columns collapsed">

          <h1>Check Out Some of My Works.</h1>

          <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
            {renderProjects()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
